<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">
          <p v-if="isEmpty(userInfo.id)">
            ユーザ追加
          </p>
          <p v-else>
            ユーザ編集
          </p>
        </span>

        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-user-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-text-field
            v-model="userInfo.userId"
            outlined
            :disabled="!isEmpty(userInfo.id)"
            dense
            :rules="[validators.required]"
            :error-messages="errorMessages"
            label="ユーザID"
            placeholder="ID"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userInfo.password"
            :rules="[validators.required]"
            outlined
            dense
            label="パスワード"
            placeholder="Password"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-radio-group
            v-model="userInfo.ipLimit"
            class="mt-0"
            row
            hide-details
          >
            <template #label>
            </template>
            <v-radio value="1">
              <template #label>
                <div>IPアドレス制限あり</div>
              </template>
            </v-radio>
            <v-radio value="0">
              <template #label>
                <div>IPアドレス制限なし</div>
              </template>
            </v-radio>
          </v-radio-group>

          <br>
          <v-select
            v-model="userInfo.siteId"
            :rules="[validators.required]"
            label="ログイン画面"
            :items="roleOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <label>閲覧範囲</label>
          <div
            v-for="sc in roleOptions"
            :key="sc.value"
          >
            <v-checkbox
              v-model="selectedValues"
              :value="sc.value"
              :label="sc.title"
              hide-details
            />
          </div>
          <br>
          <v-btn
            v-if="isEmpty(userInfo.id)"
            style="text-transform: none"
            color="primary"
            class="me-3"
            type="submit"
          >
            追加
          </v-btn>
          <v-btn
            v-else
            style="text-transform: none"
            color="primary"
            class="me-3"
            type="submit"
          >
            編集
          </v-btn>
          <v-btn
            style="text-transform: none"
            color="secondary"
            outlined
            type="reset"
            @click="resetuserData"
          >
            キャンセル
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { isEmpty } from '@/@core/utils'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    userInfo: {
      type: Object,
      required: true,
    },
    selectedValues: {
      type: Array,
    },
    userListTable: {
      type: Array,
      required: true,
    },
  },
  methods: {
    resetFrom() {
      this.errorMessages = ''
      this.$refs.form.resetValidation()
    },
    clearMessage() {
      this.errorMessages = ''
    },
  },
  setup(props, { emit }) {
    const valid = ref(false)
    const form = ref(null)
    const errorMessages = ref()

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    console.log('props.userInfo')
    console.log(props.userInfo)
    const resetuserData = () => {
      resetForm()
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = () => {
      const check = form.value.validate()

      if (check) {
        // const result = props.userListTable.filter(element => { return element.userId === props.userInfo.userId })
        const filteredData = props.userListTable.filter(
          user =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
            user.userId === props.userInfo.userId,
        )
        /* eslint-enable  */

        if (filteredData.length > 0 && isEmpty(props.userInfo.id)) {
          errorMessages.value = 'userId already exists'

          return
        }

        let siteIds
        let oper

        if (isEmpty(props.userInfo.id)) {
          oper = 'add'
        } else {
          oper = 'edit'
        }

        const id = String(props.userInfo.id)
        const userId = String(props.userInfo.userId)
        const password = String(props.userInfo.password)
        const ipLimit = String(props.userInfo.ipLimit)
        const siteId = String(props.userInfo.siteId)
        const lenElement = props.selectedValues.length
        for (let i = 0; i < lenElement; i += 1) {
          if (i === 0) {
            siteIds = `${props.selectedValues[i]}`
          } else {
            siteIds = `${siteIds},${props.selectedValues[i]}`
          }
        }

        store
          .dispatch('app-user/editUser', {
            params: {
              id,
              userId,
              siteId,
              siteIds,
              password,
              ipLimit,
              oper,
            },
          })
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-new-user-sidebar-active', false)
          })
          .catch(error => {
            console.log(error)
            this.$router.push({ name: 'error-login' })
          })
      } else {
        validate()
      }
    }

    return {
      resetuserData,
      form,
      onSubmit,
      validate,
      valid,
      isEmpty,
      errorMessages,

      // validation
      validators: { required },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
